import { useApolloClient } from "@apollo/client";
import AsyncStorage from "@react-native-async-storage/async-storage";
import _ from "lodash";
import moment from "moment/moment";
import React, { useEffect, useReducer, useRef, useState } from "react";
import {
  ActivityIndicator,
  BackHandler,
  Platform,
  StyleSheet,
  Text,
  View,
} from "react-native";

import AnswerFeedback from "./AnswerFeedback/AnswerFeedback";
import { AnswerStatus } from "./AnswerFeedback/Draw";
import ConnectionRenderer from "./ConnectionRenderer";
import { ElapsedTime, ExerciseDataType } from "./ExerciseInterfaces";
import { EXERCISE_INITIAL_STATUS, exerciseReducer } from "./ExerciseReducer";
import Image from "./Image";
import Info from "./Info";
import ModalExerciseCompletedFlag from "./ModalExerciseCompletedFlag";
import ModalExerciseSkipped from "./ModalExerciseSkipped";
import SvgB9, { RectB9 } from "./SvgB9";
import { getNextExercise, modifyCacheFlags } from "./functionsExercise";
import { processCx8Exercise } from "./processCx8Exercise";
import {
  ExerciseActions,
  ExerciseActionsType,
  TypeEvents,
} from "./reducerExerciseInterfaces";
import {
  ExerciseWithStatus,
  ExerciseProgressUploadType,
} from "../../Interfaces";
import { containerStyles } from "../../constants/NewStyles";
import { ActionExerciseIndexType } from "../../hooks/reducerIntefaces";
import { Logger } from "../Logger";
import RectOnFocus from "../Recuadro/RectOnFocus";
import Recuadro from "../Recuadro/Recuadro";
import TopBar from "../TopBarComponents/TopBar";
import { exerciseTimerStorageKey, exerciseUserInfoStorageKey } from "../Utils";
import { getOnlyConnection } from "../functions/exerciseFunctions";

interface ComputeStyleTypeReturn {
  FLEX_DIRECTION: "row" | "column";
  isHorizontal: boolean;
}

function computeStyle(screenInfo): ComputeStyleTypeReturn {
  return {
    FLEX_DIRECTION: screenInfo.isHorizontal ? "row" : "column",
    isHorizontal: screenInfo.isHorizontal,
  };
}

export default function Exercise({
  getCx8File,
  screenInfo,
  exerciseIndex,
  exerciseIndexDispatch,
  getExercise,
  navigation,
  route,
}) {
  const [exerciseStatus, exerciseDispatch] = useReducer<
    React.Reducer<ExerciseDataType, ExerciseActionsType>
  >(exerciseReducer, EXERCISE_INITIAL_STATUS);
  const { usuarioId, cursoId, pais } = exerciseIndex;
  const exerciseId = route.params.exerciseId;
  const tareaAsignadaId = route.params.tareaAsignadaId;
  const position = route.params.position;
  const timeKey = exerciseTimerStorageKey(
    exerciseId,
    tareaAsignadaId,
    position
  );
  const elapsedTimeRef = useRef(0);
  const [exerciseUpdatedAt, setExerciseUpdatedAt] = useState(0);
  const exerciseRedrawKey = `${tareaAsignadaId}-${tareaAsignadaId}-${position}-${screenInfo.isHorizontal}`;
  const [forceHideBanderasModal, setForceHideBanderasModal] = useState(false);
  const [showSkipModal, setShowSkipModal] = useState(false);
  const [answerFeedback, setAnswerFeedback] = useState({
    status: AnswerStatus.UNKNOWN,
    ts: 0,
  });

  const style = computeStyle(screenInfo);
  const [isDragMode, setIsDragMode] = useState(false);
  const client = useApolloClient();

  //desactiva la opción de regresar desde esta pantalla
  //esto para cuando los ejercicios tienen conexion muy cerca del borde derecho y gatilla un regresar a la pantalla
  //anterior
  useEffect(() => {
    if (Platform.OS !== "web") {
      BackHandler.addEventListener("hardwareBackPress", () => true);
      return () =>
        BackHandler.removeEventListener("hardwareBackPress", () => true);
    }
  }, []);

  //load exercise
  useEffect(() => {
    function catchErrorFallbackToIndex(error: any) {
      Logger.captureException(
        "Error al cargar ejercicio E" + exerciseId,
        error
      );
      goToTareasList();
    }

    if (exerciseId && tareaAsignadaId) {
      getExercise(exerciseId, tareaAsignadaId).then(
        (exercise: ExerciseWithStatus) => {
          if (!exercise) {
            goToTareasList();
          } else {
            setExerciseUpdatedAt(moment(exercise.updated_at).unix());

            getCx8File(
              exerciseId,
              tareaAsignadaId,
              exercise.updated_at,
              exercise.key,
              exercise.availableOffline
            )
              .then(async (cx8File) => {
                Logger.info("cx8File and exercise acquired " + exerciseId);
                const cx8 = processCx8Exercise(cx8File, screenInfo);

                Logger.info(
                  "cx8 ready for dispatch " + exerciseId,
                  tareaAsignadaId
                );
                console.log("AA elapsedTime", exercise.user.elapsedTime);

                const elapsedFromRemote: ElapsedTime = {
                  elapsed: exercise.user.elapsedTime,
                };

                const elapsedFromLocalRAW =
                  (await AsyncStorage.getItem(timeKey)) ||
                  JSON.stringify(elapsedFromRemote);
                const elapsedFromLocal = JSON.parse(
                  elapsedFromLocalRAW
                ) as ElapsedTime;

                elapsedTimeRef.current = Math.max(
                  elapsedFromRemote.elapsed,
                  elapsedFromLocal.elapsed
                );
                console.log("AA Setting ElapsedTime", elapsedTimeRef.current);

                exerciseDispatch({
                  type: ExerciseActions.READY,
                  payload: cx8,
                  exercise,
                  onlyConnection: exercise.user.onlyConnection
                    ? exercise.user.onlyConnection
                    : getOnlyConnection(cx8.validator.data),
                });
              })
              .catch((error: any) => {
                catchErrorFallbackToIndex(error);
              });
          }
        }
      );
    }
  }, [route.params, screenInfo]);

  // //hide answer feedback
  useEffect(() => {
    const isMoving = exerciseStatus.transient.lastEvent === answerFeedback.ts;

    if (!isMoving) {
      setAnswerFeedback({
        status: exerciseStatus.transient.answerFeedback,
        ts: exerciseStatus.transient.lastEvent,
      });
      if (exerciseStatus.transient.answerFeedback !== AnswerStatus.UNKNOWN) {
        setTimeout(() => {
          setAnswerFeedback({
            status: AnswerStatus.UNKNOWN,
            ts: exerciseStatus.transient.lastEvent,
          });
        }, SHOW_ANSWER_FEEDBACK_MS);
      }
    }
  }, [
    exerciseStatus.transient.answerFeedback,
    exerciseStatus.transient.lastEvent,
    answerFeedback,
  ]);

  //save data in local storage
  useEffect(() => {
    if (!exerciseStatus.transient.pendingSave) {
      return;
    }
    const key = exerciseUserInfoStorageKey(
      exerciseId,
      tareaAsignadaId,
      exerciseStatus.position
    );

    //banderas sólo se almacenan si el ejercicio ha terminado
    if (exerciseStatus.user.completed) {
      const key = JSON.stringify({ curso_id: cursoId, usuario_id: usuarioId });

      const newFlags = modifyCacheFlags(exerciseStatus.user.banderas);

      client.cache.modify({
        id: "UsuarioCurso:" + key,
        fields: newFlags,
      });
    }

    const exportable: ExerciseProgressUploadType = {
      type: "exercise",
      completed: exerciseStatus.user.completed,
      payload: { ...exerciseStatus.user, elapsedTime: elapsedTimeRef.current },
      tareaAsignadaId,
      exerciseId,
      position,
    };

    AsyncStorage.setItem(key, JSON.stringify(exportable)).then(() => {
      exerciseDispatch({
        type: ExerciseActions.RESET_PENDING_SAVE_FLAG,
      });
      exerciseIndexDispatch({
        type: ActionExerciseIndexType.SEND_PROGRESS,
        payload: exportable,
      });
    });
  }, [exerciseStatus.transient.pendingSave]);

  const handleTouchEvent = (event, state: ExerciseDataType, isDragMode) => {
    if (event.target.id === "buttonPlay") {
      return;
    }
    if (isDragMode) {
      //ignore first event when switching from drag to touch to avoid recognizing the same event twice
      setIsDragMode(false);
      return;
    }
    if (state.user.onlyConnection.exist) {
      const touchEvent = _.pick(event.nativeEvent, ["pageX", "pageY"]);
      const synthesizedEvent = {
        recuadroId: state.user.onlyConnection.recuadroId,
        connectionId: state.user.onlyConnection.connectionId,
        segmentoId: state.user.onlyConnection.segmentoId,
        startX: state.user.onlyConnection.startX,
        startY: state.user.onlyConnection.startY,
        x0:
          (touchEvent.pageX -
            state.fixed.origenPosition.x -
            state.fixed.origenPosition.ajusteTouchX) /
          state.transient.scalingFactor,
        y0:
          (touchEvent.pageY -
            state.fixed.origenPosition.y -
            state.fixed.origenPosition.ajusteTouchY) /
          state.transient.scalingFactor,
        dx: 0,
        dy: 0,
        timestamp: new Date().getTime(),
        scalingFactor: state.transient.scalingFactor,
        remainingSegmentos: state.user.onlyConnection.remainingSegmentos,
        typeEvent: TypeEvents.TOUCH,
      };
      exerciseDispatch({
        type: ExerciseActions.TOUCH_EVENT,
        inputEvent: synthesizedEvent,
      });
    }
  };

  const onConnectionMoving = (event) => {
    exerciseDispatch({
      type: ExerciseActions.CONNECTION_MOVING,
      inputEvent: {
        ...event,
        timestamp: new Date().getTime(),
        typeEvent: TypeEvents.DRAG,
      },
    });
  };

  const onConnectionRelease = (event) => {
    setIsDragMode(true);
    exerciseDispatch({
      type: ExerciseActions.CONNECTION_RELEASE,
      inputEvent: {
        ...event,
        timestamp: new Date().getTime(),
        typeEvent: TypeEvents.DRAG,
      },
    });
  };

  const checkAnswer = (answer: string) => {
    exerciseDispatch({
      type: ExerciseActions.TEXT_ANSWER,
      answer,
      inputEvent: {
        timestamp: new Date().getTime(),
        typeEvent: TypeEvents.TEXT,
      },
    });
  };

  const onRecuadroFocus = ({
    recId,
    questionText,
    styles,
    correctAnswer,
    changeText,
    answerIsNumber,
    typeReviewAnswer,
  }) => {
    exerciseDispatch({
      type: ExerciseActions.OPEN_INPUT_MODAL,
      payload: {
        styles,
        questionText,
        answerIsNumber,
        idModal: recId,
        correctAnswer,
        typeReviewAnswer,
        changeText,
      },
    });
  };

  const removeRecFocus = () => {
    exerciseDispatch({ type: ExerciseActions.HIDE_INPUT_MODAL });
  };

  const goToNextExercise = () => {
    const nextExercise = getNextExercise(exerciseIndex.tareas, {
      tareaAsignadaId,
      exerciseId,
      position,
    });

    navigation.replace(nextExercise.screen, nextExercise.params);
  };

  const goToTareasList = () => {
    navigation.replace("Tareas");
  };

  const saveProgress = (tiempoAcumulado: number) => {
    console.log("AA reportProgress", tiempoAcumulado, timeKey);

    const data: ElapsedTime = { elapsed: tiempoAcumulado };
    AsyncStorage.setItem(timeKey, JSON.stringify(data));

    // reportExercise(client, TYPES.progress, {
    //   ...exerciseStatus,
    //   user: { ...exerciseStatus.user, elapsedTime: tiempoAcumulado },
    // });
  };

  const omitirEjercicio = () => {
    exerciseDispatch({ type: ExerciseActions.SKIP_EXERCISE });
  };

  // const exerciseStatus = exerciseIndex.exerciseStatus;
  if (exerciseStatus.transient.loading) {
    return (
      <View style={containerStyles.centered}>
        <Text>
          Cargando ejercicio TA{tareaAsignadaId}E{exerciseId}
        </Text>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  const EJERCICIO_CENTRADO = screenInfo.isHorizontal
    ? Math.trunc(
        (screenInfo.windowWidth -
          WIDTH_BAR -
          exerciseStatus.transient.window.w) /
          HALF
      )
    : 0;

  return (
    <View
      style={[
        styles.mainContainer,
        {
          flexDirection: style.FLEX_DIRECTION,
        },
      ]}
      key={exerciseRedrawKey}
    >
      <TopBar
        timeRef={elapsedTimeRef}
        userStatus={exerciseStatus.user}
        screenInfo={screenInfo}
        openSkipModal={setShowSkipModal}
        pais={pais}
        info={
          style.isHorizontal ? (
            <Info
              exercise={exerciseId}
              tarea={tareaAsignadaId}
              isHorizontal={style.isHorizontal}
              exerciseUpdatedAt={exerciseUpdatedAt}
            />
          ) : null
        }
        saveProgress={saveProgress}
        goToTareasList={goToTareasList}
      />
      <View
        style={[styles.exerciseContainer, { marginLeft: EJERCICIO_CENTRADO }]}
      >
        <SvgB9
          height={exerciseStatus.transient.window.h}
          width={exerciseStatus.transient.window.w}
          viewBox={exerciseStatus.transient.svgViewBox}
          preserveAspectRatio="xMinYMin"
          onPress={(event) =>
            handleTouchEvent(event, exerciseStatus, isDragMode)
          }
        >
          <RectB9
            x={0}
            y={0}
            width={exerciseStatus.fixed.exerciseWidth}
            height={exerciseStatus.fixed.exerciseHeight}
            stroke="white"
            strokeWidth={4}
            fill={exerciseStatus.transient.colorFondo}
          />
          {_.map(exerciseStatus.fixed.imagenes, (img) => {
            return <Image key={img.id} {...img} />;
          })}

          {_.map(exerciseStatus.fixed.recuadros, (rec) => {
            const recId = "rec" + rec["@id"];

            const answer = exerciseStatus.user.answers[recId]?.answer || "";
            return (
              <Recuadro
                key={recId + "-" + answer}
                {...rec}
                userAnswer={exerciseStatus.user.answers[recId]}
                userConnections={exerciseStatus.user.connections}
                recuadroId={recId}
                onInputOnFocus={onRecuadroFocus}
                onConnectionMoving={onConnectionMoving}
                onConnectionRelease={onConnectionRelease}
                scalingFactor={exerciseStatus.transient.scalingFactor}
              />
            );
          })}

          <ConnectionRenderer
            connections={exerciseStatus.user.connections}
            onConnectionMoving={onConnectionMoving}
            onConnectionRelease={onConnectionRelease}
            scalingFactor={exerciseStatus.transient.scalingFactor}
          />
        </SvgB9>
        {!style.isHorizontal && (
          <Info
            exercise={exerciseId}
            tarea={tareaAsignadaId}
            isHorizontal={style.isHorizontal}
            exerciseUpdatedAt={exerciseUpdatedAt}
          />
        )}
      </View>
      <AnswerFeedback answer={answerFeedback} />
      {exerciseStatus.transient.modalInputVisible ? (
        <RectOnFocus
          styles={exerciseStatus.transient.styles.text}
          questionText={exerciseStatus.transient.questionText}
          recId={exerciseStatus.transient.idModal}
          answerIsNumber={exerciseStatus.transient.answerIsNumber}
          changeText={exerciseStatus.transient.changeText}
          onCheckAnswer={checkAnswer}
          onRemoveFocus={removeRecFocus}
          autoFocus
        />
      ) : null}
      {exerciseStatus.user.completed && !forceHideBanderasModal && (
        <ModalExerciseCompletedFlag
          numberOfFlags={exerciseStatus.user.banderas}
          setForceHideBanderasModal={setForceHideBanderasModal}
          onGoToNextExercise={goToNextExercise}
        />
      )}

      {showSkipModal && (
        <ModalExerciseSkipped
          setShowSkipModal={setShowSkipModal}
          onGoToNextExercise={omitirEjercicio}
        />
      )}
    </View>
  );
}

const WIDTH_BAR = 76;
const HALF = 2;
const SHOW_ANSWER_FEEDBACK_MS = 2500;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "stretch",
  },
  exerciseContainer: {
    alignItems: "center",
  },
});
