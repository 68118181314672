import _ from "lodash";
import React from "react";
import { View, Text, Platform, Linking } from "react-native";

import PaisButton from "../../components/AuthComponents/PaisButton";
import Logo from "../../components/LogoComponents/Logo";
import { loginStyles } from "../../constants/LoginStyles";
import parameters from "../../metadata.json";
import { SelectPaisProps } from "../../navigation/Routes";
import paisesList from "../../paises/paises.json";

export default function LoginSelectPaisScreen({ navigation }: SelectPaisProps) {
  const navigateLoginUsernameScreen = (paisId: string) => {
    navigation.navigate("InputUsername", {
      paisId,
    });
  };

  return (
    <View style={loginStyles.container}>
      <View style={loginStyles.stretch}>
        <Logo />
      </View>

      <Text style={loginStyles.flatListText}>Selecciona tu país</Text>
      <View style={loginStyles.flatListContainer}>
        {_.map(paisesList, (item) => {
          return (
            <PaisButton
              key={item.id}
              pais={item}
              onPress={() => navigateLoginUsernameScreen(item.id)}
            />
          );
        })}
      </View>

      <Text
        style={loginStyles.linkText}
        allowFontScaling={false}
        onPress={() =>
          Linking.openURL("https://www.conectaideas.com/privacidad.html")
        }
      >
        Política de privacidad
      </Text>

      <Text allowFontScaling={false}>
        v. {parameters.version}
        {Platform.OS[0]}
      </Text>
    </View>
  );
}
