import { activateKeepAwakeAsync, deactivateKeepAwake } from "expo-keep-awake";
import React, { useContext, useEffect } from "react";
import { Platform } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import { ConectaIdeasContext } from "../components/ConectaIdeas";
import { getCx8File } from "../components/ConectaIdeas/ConectaIdeasClient";
import Exercise from "../components/ExerciseComponents/Exercise";
import useScreenInfo from "../hooks/useScreenInfo";
import { ExerciseScreenProps } from "../navigation/Routes";

export default function ExerciseScreen({
  navigation,
  route,
}: ExerciseScreenProps) {
  const screenInfo = useScreenInfo();
  const { exerciseIndex, exerciseIndexDispatch, getExercise } =
    useContext(ConectaIdeasContext);

  useEffect(() => {
    if (Platform.OS !== "web") {
      activateKeepAwakeAsync();
      return () => {
        deactivateKeepAwake();
      };
    }
  }, []);

  if (screenInfo.loading) {
    return null;
  }

  if (exerciseIndex.loading) {
    return null;
  }

  return (
    <SafeAreaView
      style={{ flex: 1, borderColor: "green", borderWidth: 1 }}
      mode="margin"
    >
      <Exercise
        getCx8File={getCx8File}
        screenInfo={screenInfo}
        exerciseIndex={exerciseIndex}
        exerciseIndexDispatch={exerciseIndexDispatch}
        getExercise={getExercise}
        navigation={navigation}
        route={route}
      />
    </SafeAreaView>
  );
}
