import _ from "lodash";

import { flagsCurrentYear, updateExercise } from "./functionUseExerciseIndex";
import { listPendingDownloadExercises } from "./indexFunctions";
import {
  ActionExerciseIndexType,
  ExerciseIndexAction,
} from "./reducerIntefaces";
import {
  ExerciseIndexState,
  ExerciseProgressUploadType,
  ExerciseWithStatus,
  TareaWithStatus,
} from "../Interfaces";
import {
  exerciseUserInfoStorageKey,
  metaAnswerUserInfoStorageKey,
} from "../components/Utils";

export const Source = {
  LOCAL: "LOCAL",
  REMOTE: "REMOTE",
  UNKNOWN: "UNKNOWN",
};

export const TAREA_ID_TEST_MODE = 1337;

export function updateExerciseWithProgress(
  state: ExerciseIndexState,
  payload: ExerciseProgressUploadType
): ExerciseIndexState {
  const newState = _.cloneDeep(state);

  if (payload.tareaAsignadaId === TAREA_ID_TEST_MODE) {
    return newState;
  }

  const findTareaParams = { tarea_asignada_id: payload.tareaAsignadaId };
  const tarea = _.find(newState.tareas, findTareaParams) as
    | TareaWithStatus
    | undefined;
  if (tarea) {
    const findEjercicioParams = {
      position: payload.position,
      tarea_asignada_id: payload.tareaAsignadaId,
      id: payload.exerciseId,
    };

    const ejercicio = _.find(tarea.ejercicios, findEjercicioParams) as
      | ExerciseWithStatus
      | undefined;
    if (ejercicio) {
      const oldData = ejercicio.user;
      const newData = payload.payload;
      ejercicio.user = { ...oldData, ...newData };
    } else {
      console.error("No se encuentra ejercicio", findEjercicioParams);
      console.error("DEBUG", JSON.stringify(tarea.ejercicios, null, 2));
    }
  } else {
    console.error("No se encuentra tarea", findTareaParams);
  }

  return newState;
}

export function reducerExerciseIndex(
  state: ExerciseIndexState,
  action: ExerciseIndexAction
): ExerciseIndexState {
  switch (action.type) {
    case ActionExerciseIndexType.RELOAD_EXERCISE_INDEX: {
      const stateWithFlags = reducerExerciseIndex(state, {
        type: ActionExerciseIndexType.SET_FLAGS,
        flags: action.flags,
      });

      return {
        ...stateWithFlags,
        ...action.dataAlumno,
        preguntasMetacognitivasIndex: action.preguntasMetacognitivasIndex,
        pendingUpload: _.isEmpty(state.pendingUpload)
          ? action.pendingUpload
          : state.pendingUpload,
        tareas: action.exerciseIndex,
        exercisesSource: action.source,
        loading: false,
        pendingDownloadExercises: action.pendingDownloadExercises,
      };
    }

    case ActionExerciseIndexType.SET_FLAGS: {
      const currentFlagCount = flagsCurrentYear(state.flags);
      const newFlagCount = flagsCurrentYear(action.flags);
      if (newFlagCount > currentFlagCount) {
        return {
          ...state,
          flags: action.flags,
        };
      } else {
        return state;
      }
    }

    case ActionExerciseIndexType.EXERCISE_DOWNLOADED: {
      const newTareas = updateExercise(
        state.tareas,
        action.tareaAsignadaId,
        action.exerciseId,
        { availableOffline: true }
      );
      const pendingDownloadExercises = listPendingDownloadExercises(newTareas);
      return {
        ...state,
        tareas: newTareas,
        pendingDownloadExercises,
      };
    }
    case ActionExerciseIndexType.SEND_PROGRESS: {
      const key = exerciseUserInfoStorageKey(
        action.payload.exerciseId,
        action.payload.tareaAsignadaId,
        action.payload.position
      );
      const newState = updateExerciseWithProgress(state, action.payload);

      newState.pendingUpload[key] = {
        ...action.payload,
      };

      return newState;
    }
    case ActionExerciseIndexType.SEND_META_ANSWER: {
      const newState = _.cloneDeep(state);
      const key = metaAnswerUserInfoStorageKey(action.payload.preguntaId);
      newState.pendingUpload[key] = {
        ...action.payload,
      };
      return newState;
    }
    case ActionExerciseIndexType.EXERCISE_REPORTED: {
      const newState = _.cloneDeep(state);
      delete newState.pendingUpload[action.key];
      return newState;
    }
  }
}
