export const exerciseLocalStorageKey = (
  exerciseId: number,
  tareaAsignadaId: number
) => {
  return `EXERCISE:${exerciseId}:${tareaAsignadaId}`;
};

export const USER_INFO_PREFIX = "USER_v5:";

export function exerciseUserInfoStorageKey(
  exerciseId: number,
  tareaAsignadaId: number,
  position: number
): string {
  return `${USER_INFO_PREFIX}EX:${tareaAsignadaId}:${exerciseId}:${position}`;
}

/**
 * Timer es almacenado independiente de los datos del ejercicio, dado que esta
 * llave se actualiza mucho más seguido quiero evitar posibles colisiones de
 * escritura
 * @param exerciseId
 * @param tareaAsignadaId
 * @param position
 */
export function exerciseTimerStorageKey(
  exerciseId: number,
  tareaAsignadaId: number,
  position: number
): string {
  return `${USER_INFO_PREFIX}TIMER:${tareaAsignadaId}:${exerciseId}:${position}`;
}

export function metaAnswerUserInfoStorageKey(preguntaId: number): string {
  return `${USER_INFO_PREFIX}META:${preguntaId}`;
}
