import { Audio } from "expo-av";
import React, { useMemo, useEffect } from "react";
import { Platform } from "react-native";
import { Svg, G, Polygon } from "react-native-svg";

import { speechToText, stop } from "./SpeechToText";
import { RectB9 } from "../ExerciseComponents/SvgB9";
import { Logger } from "../Logger";

const WAIT_MS = 700;
const AJUSTE = {
  FONT_SIZE: 22,
  BORDER: 2,
};
const PLAY = {
  WIDTH: 24,
  HEIGHT: 30,
};

const emptySound = require("../../../assets/empty.mp3");

function replaceWithSingularAndPlural(
  text: string,
  plural: string,
  singular: string
) {
  const match = text.match(/(\d+)/);
  if (match) {
    const number = parseInt(match[1], 10);
    if (number > 1) {
      return `${number} ${plural} `;
    } else {
      return `${number} ${singular} `;
    }
  } else {
    return text;
  }
}

/**
 * Recibe un texto y lo retorna de la forma en la cual debería leerse
 * @param text
 * @returns text
 */
export function preprocessarTexto(text: string) {
  const terminos = ["U", "D", "C", "Um", "CO2"];
  let textAux = text + " ";

  for (let t = 0; t < terminos.length; t++) {
    switch (terminos[t]) {
      case "Um": {
        textAux = textAux.replace(/(\d+)Um /g, (str) => {
          return replaceWithSingularAndPlural(
            str,
            "Unidades de mil",
            "Unidad de mil"
          );
        });
        break;
      }

      case "U": {
        textAux = textAux.replace(/(\d+)U /g, (str) => {
          return replaceWithSingularAndPlural(str, "Unidades", "Unidad");
        });
        break;
      }
      case "D": {
        textAux = textAux.replace(/(\d+)D /g, (str) => {
          return replaceWithSingularAndPlural(str, "Decenas", "Decena");
        });
        break;
      }
      case "C": {
        textAux = textAux.replace(/(\d+)C /g, (str) => {
          return replaceWithSingularAndPlural(str, "Centenas", "Centana");
        });
        break;
      }
      case "CO2": {
        textAux = textAux.replace(/ CO2 /g, " C O2 ");
        break;
      }
    }
  }
  return textAux.trim();
}

interface BoxTypeStyle {
  top: number;
  left: number;
  width: number;
  height: number;
  backgroundColor: string;
  borderWidth: number;
  borderColor: string;
  opacity: number;
}

interface BoxSpeechType {
  box: BoxTypeStyle;
  text: string;
  fontSize: number;
  hasConnections: boolean;
}

export default function BoxSpeech({
  box,
  text,
  fontSize,
  hasConnections,
}: BoxSpeechType) {
  const [colorPlay, onChangeColorPlay] = React.useState("black");
  const [playing, onChangeStateAudio] = React.useState(false);
  const audio = useMemo(() => {
    return new Audio.Sound();
  }, []);

  useEffect(() => {
    if (playing) {
      try {
        const enableSound = async () => {
          if (Platform.OS === "ios") {
            await Audio.setAudioModeAsync({
              playsInSilentModeIOS: true,
            });
            await audio.loadAsync(emptySound);
            await audio.playAsync();
          }
          setTimeout(() => {
            speak();
          }, WAIT_MS);
        };
        enableSound();
      } catch (error) {
        Logger.info("ERROR SONIDO TEXT:", error);
      }
    }
    return () => {
      audio.unloadAsync();
    };
  }, [playing]);

  const textAux = preprocessarTexto(text);

  async function speak() {
    const options = {
      onStart: () => {
        onChangeColorPlay("green");
      },
      onDone: () => {
        onChangeColorPlay("black");
        onChangeStateAudio(false);
      },
      onStopped: () => {
        onChangeColorPlay("black");
        onChangeStateAudio(false);
      },
    };
    await speechToText(textAux, options);
  }

  let scaleRatio = 1;
  if (fontSize < AJUSTE.FONT_SIZE) {
    scaleRatio = fontSize / AJUSTE.FONT_SIZE;
  }
  const width = PLAY.WIDTH * scaleRatio;
  const height = PLAY.HEIGHT * scaleRatio;

  return (
    <G
      x={box.left + box.width - AJUSTE.BORDER * width}
      y={
        hasConnections
          ? box.top - box.borderWidth / AJUSTE.BORDER
          : box.top + box.height - PLAY.HEIGHT * scaleRatio
      }
    >
      <G x={width} y={0}>
        <Svg width={width} height={height} viewBox="0 0 24 30">
          <RectB9
            height={30}
            width={24}
            stroke="black"
            strokeWidth={1}
            fill="lightgray"
            rx={3}
            ry={3}
          />
          <Polygon points="4,6 20,15 4,24" fill={colorPlay} />
        </Svg>
      </G>
      <RectB9
        id="buttonPlay"
        height={50}
        width={48}
        strokeWidth={1}
        rx={3}
        ry={3}
        fillOpacity={0}
        onPressIn={() => (playing ? stop() : onChangeStateAudio(true))}
      />
    </G>
  );
}
