import {
  ApolloClient,
  createHttpLink,
  gql,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { Auth } from "@aws-amplify/auth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import {
  AsyncStorageWrapper,
  LocalStorageWrapper,
  persistCache,
} from "apollo3-cache-persist";
import { AUTH_TYPE } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import axios from "axios";
import Constants from "expo-constants";
import _ from "lodash";
import moment from "moment";
import { Platform } from "react-native";

import ConectaStorage from "./ConectaStorage";
import { authConfig } from "../../../Config";
import {
  Cx8File,
  ExerciseProgressUploadType,
  GenericUploadType,
  MetaAnswerUploadType,
} from "../../Interfaces";
import { uploadEvaluacion } from "../../graphql/mutation";
import { INITIAL_DATA_FETCH } from "../../graphql/queries";
import { Sentry } from "../../lib/Sentry";
import parameters from "../../metadata.json";
import { Logger } from "../Logger";
import { clearLocalStorage, removeExerciseFromCache } from "../Storage";
import { exerciseTimerStorageKey } from "../Utils";
import { getTokenFromStorage } from "../functions/storageFunctions";

const version = parameters.version;

Logger.info("AuthConfig1", authConfig);
Auth.configure(authConfig);
const GRAPHQL = Constants.expoConfig.extra?.graphqlLink;
const ENDPOINT = Constants.expoConfig.extra?.cognitoRails;
const CX8_ENDPOINT = Constants.expoConfig.extra?.cx8Url;
const CDN_ENDPOINT = ENDPOINT;

Logger.info("endpoint", GRAPHQL);
Logger.info("NODE_ENV", process.env.NODE_ENV);

const HTTP_SUCCESS = 200;
const ONE_SECOND_MS = 1000;
const MAX_POINTS = 6.0;
const AJUSTE_DECIMAL = 10;
const MAX_FLAGS = 3;
const MAX_NOTA = 7;

class ConectaIdeasClient {
  private storage: ConectaStorage;

  constructor() {
    this.storage = new ConectaStorage();
  }

  async getCx8File(
    exerciseId: number,
    tareaAsignadaId: number,
    updatedAt: string,
    exerciseKey: string
  ): Promise<Cx8File> {
    const fullUrl = await this.getRemoteExerciseFullUrl(
      exerciseId,
      updatedAt,
      exerciseKey
    );
    const cx8 = await this.storage.getExercise(
      exerciseId,
      tareaAsignadaId,
      updatedAt,
      fullUrl
    );
    return cx8;
  }

  async getRemotePath(relativePath, authenticated = true) {
    if (authenticated) {
      const accessToken = await this.getAccessToken();
      return `${ENDPOINT}${relativePath}?accessToken=${accessToken}`;
    } else {
      return `${CDN_ENDPOINT}${relativePath}`;
    }
  }

  async getAccessToken() {
    const currentSession = await Auth.currentSession();
    const remainingTime = Math.round(
      currentSession.getAccessToken().payload.exp -
        new Date().getTime() / ONE_SECOND_MS
    );
    Logger.debug("RemainingAuthTime", remainingTime);

    if (!currentSession.isValid()) {
      //refresh token
      throw new Error("Token es inválido");
    }
    return currentSession.getAccessToken().getJwtToken();
  }

  async getRemoteExerciseFullUrl(exerciseId, updatedAt, exerciseKey) {
    const updatedEpoch = moment(updatedAt).unix();
    if (exerciseKey) {
      return `${CX8_ENDPOINT}/cx8/${exerciseKey}.cx8?v=${updatedEpoch}`;
    } else {
      return this.getRemotePath(
        `/cx8/${exerciseId}.gz?v=${updatedEpoch}`,
        false
      );
    }
  }

  /**
   * Envia la nota de un ejercicio al servidor de conectaIdeas.
   *
   * @returns verdadero si es que la nota fue enviada exitosamente, falso si no fue enviada y se debe reintentar
   */
  async uploadEvaluation(
    apolloClient: ApolloClient<object>,
    params: ExerciseProgressUploadType
  ) {
    const token = await getTokenFromStorage();

    const evaluacion = {
      exerciseId: params.exerciseId,
      tareaAsignadaId: params.tareaAsignadaId,
      position: params.position,
      startTime: params.payload.startTime,
      nota: params.payload.nota,
      errorCount: params.payload.errorCount,
      elapsedTime: Math.ceil(params.payload.elapsedTime / ONE_SECOND_MS),
      banderas: params.payload.banderas,
      omitido: params.payload.omitido,
      respuesta: JSON.stringify({
        answers: params.payload.answers,
        connections: params.payload.connections,
      }),
      platform: Platform.OS,
      version: parameters.version + "-" + Platform.OS,
      deviceId: Platform.OS === "web" ? "web" : token || "no-token",
    };

    Logger.info("AFF RESPUESTA A ENVIAR:", evaluacion);

    return apolloClient
      .mutate({
        mutation: gql(uploadEvaluacion),
        variables: { evaluacion, version },
        update: (cache) => {
          cache.updateQuery({ query: INITIAL_DATA_FETCH }, (data) => ({
            ...data,
            getActividades: {
              tareas: _.map(data.getActividades.tareas, (tarea) => {
                if (tarea.tarea_asignada_id === evaluacion.tareaAsignadaId) {
                  const ejercicios = _.filter(tarea.ejercicios, (e) => {
                    return (
                      e.id !== evaluacion.exerciseId ||
                      e.position !== evaluacion.position
                    );
                  });
                  return { ...tarea, ejercicios };
                }
                return tarea;
              }),
            },
          }));
        },
      })
      .then(async (output) => {
        Logger.info("AFF ejercicio sincronizado", output.data.uploadEvaluacion);
        try {
          await removeExerciseFromCache(
            params.exerciseId,
            params.tareaAsignadaId
          );
          const timeKey = exerciseTimerStorageKey(
            params.exerciseId,
            params.tareaAsignadaId,
            params.position
          );
          await AsyncStorage.removeItem(timeKey);
          Logger.info("AFF ejercicio eliminado de cache");
        } catch (error) {
          console.error("Error eliminando a ejercicio desde cache", error);
        }
        return true;
      })
      .catch((error) => {
        Logger.captureException("AFF ejercicio no sincronizado", error);
        return false;
      });
  }

  /**
   * Envia respuesta de preguntas metacognitivas al servidor de conectaIdeas.
   *
   * @param preguntaId
   * @param respuesta
   * @returns verdadero si es que la nota fue enviada exitosamente, falso si no fue enviada y se debe reintentar
   */
  async sendAnswer(preguntaId: number, respuesta: string) {
    const request = {
      texto: respuesta,
    };
    const remotePath = await this.getRemotePath(
      `/preguntas/respuesta_alumno/${preguntaId}`,
      true
    );

    return axios
      .post(remotePath, request)
      .then((response) => {
        if (response.status !== HTTP_SUCCESS) {
          throw new Error(
            "error sending answer. ResponseCode " + response.status
          );
        } else {
          return response.status;
        }
      })
      .then((output) => {
        Logger.info("respuesta enviada", output);
        return true;
      })
      .catch((error) => {
        Logger.captureException("respuesta no enviada", error);
        return false;
      });
  }

  async sendAlternativasAnswer({ preguntaId, respuestaIds, multiple }) {
    const request = {
      pregunta_id: preguntaId,
      respuestas: JSON.stringify(respuestaIds),
      multiple,
    };
    const remotePath = await this.getRemotePath(
      `/preguntas/respuesta_alumno/${preguntaId}`,
      true
    );

    return axios
      .post(remotePath, request)
      .then((response) => {
        if (response.status !== HTTP_SUCCESS) {
          throw new Error(
            "error sending answer. ResponseCode " + response.status
          );
        } else {
          return response.status;
        }
      })
      .then((output) => {
        Logger.info("respuesta enviada", output);
        return true;
      })
      .catch((error) => {
        Logger.captureException("respuesta no enviada", error);
        return false;
      });
  }

  async signIn(usernameId, password) {
    //make sure that we always start with a clear index
    await clearLocalStorage();

    const user = await Auth.signIn(String(usernameId), password);
    const userData = [];
    userData.push(["username", user.username]);

    const atributos = JSON.stringify(user.attributes);
    userData.push(["userAttributes", atributos]);

    await AsyncStorage.multiSet(userData, (error) => {
      if (error) {
        Logger.captureException(
          "ERROR GUARDANDO ATRIBUTOS DE USUARIO EN localStorage:",
          error
        );
      } else {
        Logger.info(
          "Atributos de usuario guardados en localStorage:",
          atributos
        );
      }
    });

    Sentry.setUser({ username: user.username });

    await this.getAccessToken();

    return user;
  }

  async signOut() {
    try {
      await Auth.signOut();
      // dispatch({ type: "SIGN_OUT" });
    } catch (error) {
      Logger.captureException("Error cerrar sesión", error);
    }
    try {
      // await clearStorage();
    } catch (error) {
      Logger.captureException("Error borrando storage", error);
    }
  }
}

const client = new ConectaIdeasClient();

export default ConectaIdeasClient;

export const retrieveIndexFromStorage = async () => {
  return await AsyncStorage.getItem("Index").then((r) => {
    if (r) {
      return JSON.parse(r);
    } else {
      Logger.info("Indice local de ejercicios vacío");
      return { tareas: [] };
    }
  });
};

export const getCx8File = (
  exerciseId,
  tareaAsignadaId,
  updatedAt,
  exerciseKey
) => {
  return client.getCx8File(exerciseId, tareaAsignadaId, updatedAt, exerciseKey);
};

export const calcularNota = (maxRespuestasCorrectas, errores) => {
  let nota;
  if (maxRespuestasCorrectas > 0) {
    //factor de ajuste para cálculo de nota tareas
    const fFactNotaTra = 3.0;

    nota =
      1.0 +
      MAX_POINTS *
        (1 / (1 + errores / (fFactNotaTra * maxRespuestasCorrectas)));
  } else {
    nota = 1;
  }

  //ajusta nota a un solo decimal
  nota = Math.round(nota * AJUSTE_DECIMAL) / AJUSTE_DECIMAL;

  return nota;
};

export const calcularBanderas = (
  maxRespuestasCorrectas: number,
  errores: number
) => {
  const nota = calcularNota(maxRespuestasCorrectas, errores);

  return _.round((1 / (MAX_NOTA - nota + 1)) * MAX_FLAGS);
};

export const uploadEvaluation = (
  apolloClient: ApolloClient<object>,
  params: GenericUploadType
) => {
  switch (params.type) {
    case "exercise":
      return client.uploadEvaluation(
        apolloClient,
        params as ExerciseProgressUploadType
      );
    case "metaAnswer": {
      const answer = params as MetaAnswerUploadType;
      if (typeof answer.answer === "string") {
        return client.sendAnswer(answer.preguntaId, answer.answer);
      } else {
        throw new Error("Not implemented AAA");
      }
    }
    default:
      throw new Error("not implemented" + params.type);
  }
};

export const getCurrentSession = (): Promise<CognitoUserSession> => {
  return Auth.currentSession();
};

function fetchWithLogger(uri, options) {
  const body = JSON.parse(options.body);
  try {
    Sentry.addBreadcrumb({
      category: "graphql",
      level: "info",
      message: `Query ${body.operationName}`,
      data: body,
    });
  } catch (ignored) {
    //ignore sentry errors
  }

  return fetch(uri, options);
}

const httpLink = createHttpLink({
  uri: GRAPHQL,
  fetch: fetchWithLogger,
});

const authLink = setContext((request, prevContext) => {
  return getCurrentSession()
    .then((cognito) => {
      return {
        ...prevContext,
        headers: {
          ...prevContext.headers,
          Authorization: cognito.getAccessToken().getJwtToken(),
        },
      };
    })
    .catch(() => {
      return prevContext;
    });
});

const wsLink = createSubscriptionHandshakeLink({
  url: GRAPHQL,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: () => {
      return getCurrentSession().then((cognito) => {
        return cognito.getAccessToken().getJwtToken();
      });
    },
  },
  region: authConfig.region,
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

function getCacheStorage() {
  if (Platform.OS === "web") {
    return new LocalStorageWrapper(window.localStorage);
  } else {
    return new AsyncStorageWrapper(AsyncStorage);
  }
}

//get client to ApolloClient
export const getApolloClient = async () => {
  // https://www.apollographql.com/blog/community/announcing-apollo-cache-persist-cb05aec16325/
  const cache = new InMemoryCache({
    typePolicies: {
      Tarea: {
        keyFields: ["tarea_asignada_id"],
        fields: {
          ejercicios: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Ejercicio: {
        keyFields: ["id", "tarea_asignada_id", "position"],
        fields: {
          tasks: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Flags: {
        //disable normalization for flags
        keyFields: false,
      },
      Index: {
        //singleton instance
        keyFields: [],
      },
      PaginatedPreguntasMetacognitivas: {
        keyFields: [],
      },
      UsuarioCurso: {
        keyFields: ["curso_id", "usuario_id"],
      },
    },
  });

  await persistCache({
    cache,
    storage: getCacheStorage(),
    trigger: "write",
    debug: true,
  });

  const link = authLink.concat(splitLink);

  return new ApolloClient({
    link,
    cache,
    version,
  });
};
